<template>
  <div>
    <el-card >
      <template #header>
        <span style="font-size: small">{{$t('msg.route.driverRecord')}}</span>
        <el-button type="primary" size="small" @click="addRecord" style="float: right">{{ $t('msg.basicInfo.addRecord') }}</el-button>
      </template>
      <el-table :data="driverRecordList" style="font-size: smaller">
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" sortable align="center"></el-table-column>
        <el-table-column prop="chassisId" :label="$t('msg.dray.chassisId')" sortable align="center"></el-table-column>
        <el-table-column prop="driverName" :label="$t('msg.account.driverName')" sortable align="center"></el-table-column>
        <el-table-column prop="srvType" :label="$t('msg.account.srvType')" sortable align="center"></el-table-column>
        <el-table-column prop="appTime" :label="$t('msg.account.appTime')" sortable align="center"></el-table-column>
        <el-table-column prop="beginTime" :label="$t('msg.account.beginTime')" sortable align="center"></el-table-column>
        <el-table-column prop="endTime" :label="$t('msg.account.endTime')" sortable align="center"></el-table-column>
        <el-table-column prop="waitBegin" :label="$t('msg.account.waitBegin')" sortable align="center"></el-table-column>
        <el-table-column prop="waitEnd" :label="$t('msg.account.waitEnd')" sortable align="center"></el-table-column>
        <el-table-column prop="smsId" :label="$t('msg.track.smsId')" sortable align="center"></el-table-column>
        <el-table-column :label="$t('msg.rate.Operations')" align="center">
          <template #default="{row}">
            <el-button type="primary" size="small" @click="editSynInfo(row)">Edit</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Add Driver Record" v-model="chassisFormShow" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :model="chassisForm" label-position="top" label-width="200px">
        <el-row>
        <el-form-item :label="$t('msg.track.smsId')" prop="smsId">
          <el-input v-model="chassisForm.smsId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.ctnrId')" prop="ctnrId">
          <el-input v-model="chassisForm.ctnrId"></el-input>
        </el-form-item>
        </el-row>
        <el-row>
        <el-form-item :label="$t('msg.dray.chassisId')" prop="chassisId">
          <el-input v-model="chassisForm.chassisId"></el-input>
        </el-form-item>
          <el-form-item :label="$t('msg.account.appTime')" prop="appTime" required >
            <el-date-picker v-model="chassisForm.appTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.account.driverName')" prop="driverName" required>
            <el-select v-model="chassisForm.driverName">
              <el-option label="Dai" value="Dai"></el-option>
              <el-option label="Fan" value="Fan"></el-option>
              <el-option label="David" value="David"></el-option>
              <el-option label="Ricky" value="Ricky"></el-option>
              <el-option label="Eric" value="Eric"></el-option>
              <el-option label="James" value="James"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item :label="$t('msg.account.srvType')" prop="srvType" required >
          <el-select v-model="chassisForm.srvType">
            <el-option label="Terminal Pickup" value="pickup"></el-option>
            <el-option label="Delivery" value="delivery"></el-option>
            <el-option label="Empty Pickup" value="emptyPickup"></el-option>
            <el-option label="Empty Return" value="emptyReturn"></el-option>
          </el-select>
        </el-form-item>
        </el-row>
        <el-row>
        <el-form-item :label="$t('msg.account.beginTime')" prop="beginTime" required >
          <el-date-picker v-model="chassisForm.beginTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.account.endTime')" prop="endTime" required >
          <el-date-picker v-model="chassisForm.endTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
        </el-row>
        <el-row>
        <el-form-item :label="$t('msg.account.waitBegin')" prop="waitBegin" required >
          <el-date-picker v-model="chassisForm.waitBegin" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.account.waitEnd')" prop="waitEnd" required >
          <el-date-picker v-model="chassisForm.waitEnd" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="MM/DD/YYYY HH:mm:ss"></el-date-picker>
        </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.account.mileage')" prop="mileage">
            <el-input-number v-model="chassisForm.mileage" :step="1" controls-position="right"></el-input-number>
          </el-form-item>
        </el-row>
      </el-form>
      <el-button type="primary" v-on:click="recordSubmit()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'driverRecord',
  setup() {
    const curUser = localStorage.getItem('curUser')
    const driverRecordList = ref()
    const chassisFormShow = ref(false)
    const chassisForm = ref({ ctnrId: '', chassisId: '', driverName: '', srvType: '', appTime: '', beginTime: '', endTime: '', waitBegin: '', waitEnd: '', mileage: 0, smsId: '' })
    const addRecord = () => {
      chassisFormShow.value = true
    }
    const recordSubmit = () => {
      const param = 'chassisRent=' + JSON.stringify(chassisForm.value)
      const reqURL = process.env.VUE_APP_BASE + '/dray/addChassisRecord'
      axios.post(reqURL, param).then((response) => {
        driverRecordList.value = response.data
        chassisFormShow.value = false
        resetForm()
      }).catch((response) => {
        console.log(response)
      })
    }
    const editSynInfo = () => {
      console.log('editSynInfo')
    }
    onMounted(() => {
      const param = 'curUser=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDriverRecord'
      axios.post(reqURL, param).then((response) => {
        driverRecordList.value = response.data
      })
    })
    const resetForm = () => {
      chassisForm.value = { ctnrId: '', chassisId: '', driverName: '', srvType: '', appTime: '', beginTime: '', endTime: '', waitBegin: '', waitEnd: '', mileage: 0, smsId: '' }
    }
    return { curUser, driverRecordList, addRecord, editSynInfo, chassisFormShow, resetForm, chassisForm, recordSubmit }
  }
})
</script>
